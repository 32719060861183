.App {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
}

.back-container {
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding: 5px;
  width: 42px;
  height: 42px;
  display: flex;
  background-color: #dfdfdf;
  border-radius: 50%;
  margin: 5px;
  justify-content: center;
}

.circular-background {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circular-image {
  width: 23px;
  height: 23px;
  object-fit: contain;
}

.container {}