.app-main-container {
    overflow: hidden;
}

.content {
    background-color: #007234;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
}

.content-top {
    padding: 20px;
    color: white;
    font-size: 24px;
    position: fixed;
    top: 0;
    height: 50px;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    background-color: #007234;
}

.link-u {
    cursor: pointer;
}

.content-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.how-it-works-container {
    margin-top: 70px;
}

.login-image-container .fake-spacer {
    width: 100vw;
    height: 100vh;
}

.content-center .title {
    font-family: 'GothamXNarrow';
    font-size: 34px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.content-center .subtitle {
    font-family: 'GothamNarrow';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.login-image-container {
    position: relative;
}

.logo-container {
    width: 100%;
    object-fit: contain;
}

.logo-container-desktop {
    width: 100%;
}

.login-body-desktop {
    background-color: #1f6131;
    width: 460px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
}

.logo-container-desktop img {
    width: 100%;
    height: calc(100vh - 70px);
    object-fit: cover;
}

.logo {
    object-fit: cover;
    width: 100%;
}

.btn-facebooklogin {
    display: none;
}

.btn-mobileemail {
    width: 100%;
    background-color: white;
    color: black;
    font-size: 20px;
    border-style: none;
    padding: 13px;
    margin-bottom: 12px;
    border-radius: 4px;
    font-weight: bold;
    font-family: 'GothamXNarrow';
    cursor: pointer;
}

.content-agb {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 52px;
    margin-top: 60px;
}

.content-agb-element {
    margin-right: 15px;
    margin-bottom: 15px;
    opacity: 0.7;
    font-family: 'GothamNarrow';
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #ffffff;
    cursor: pointer;
}

iframe {
    background-color: #fff;
}

.btn-emaillogin {
    font-family: 'GothamNarrow';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-top: 20px;
    padding-bottom: 20px;
}

.btn-emaillogin a {
    font-family: 'GothamNarrow';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.login-body {
    z-index: 1;
    height: calc(100vh - 120px);
    display: flex;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-flex-direction: column;
    flex-direction: column;
    position: absolute;
    bottom: -20px;
    margin-top: 80px;
}

.title {
    font-family: 'GothamXNarrow';
    font-size: 32px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.login-body-desktop .title {
    margin-bottom: 14px;
}

.subtitle {
    font-family: 'GothamNarrow';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-bottom: 20px;
}

.detail-title {
    font-family: 'GothamXNarrow';
    font-size: 26px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-top: 100px;
}

.bottom-btn-container {
    border-radius: 3px;
    background-color: #006638;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.content-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-bottom: 20px;
}

.bottom-btn-container-title {
    margin-top: 30px;
    margin-bottom: 40px;
    font-family: 'GothamXNarrow';
    font-size: 26px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-bottom: 20px;
}

.link-u {
    text-decoration: underline;
}

.main-login-page {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #007234;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.info-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mobile {}

.center-image {
    object-fit: cover;
    min-width: 50%;
}

.info-block .info-block-image {
    display: block;
    width: 60%;
    margin-bottom: 20px;
}

.info-block .info-block-image {
    max-width: 320px;
}

.info-block-info {
    width: 480px;
    padding-left: 60px;
    padding-right: 60px;
    height: auto;
    align-self: center;
}

.text-body {
    font-family: GothamNarrow;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.main-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.stoerer {
    width: 198px;
    height: 198px;
    margin-top: 67px;
    margin-bottom: 40px;
}

h1 {
    font-family: 'GothamXNarrow';
    font-size: 32px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    max-width: 625px;
}

.teaser {
    opacity: 0.8;
    font-family: 'GothamNarrow';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-top: 23px;
    max-width: 625px;
}

.sogehts {
    font-family: 'GothamXNarrow';
    font-size: 26px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-top: 90px;
    margin-bottom: 60px;
}

.item-container {
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    max-width: 730px;
}

.item-container-image {
    width: 290px;
    height: auto;
}

.item-container-info-title {
    font-family: 'GothamXNarrow';
    font-size: 32px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.item-container-info-text {
    opacity: 0.8;
    font-family: 'GothamNarrow';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.28;
    letter-spacing: normal;
    color: #ffffff;
}

.mt-15 {
    margin-top: 15px;
}

.ml-15 {
    margin-left: 44px;
}

.mr-15 {
    margin-right: 44px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .mobile {
        width: 70%;
    }
}

@media only screen and (max-width: 650px) {
    .info-block {
        flex-direction: column;
        width: 100%;
        position: relative;
    }

    .btn-facebooklogin {
        display: block;
        font-size: 16px;
        border-style: none;
        padding: 15px;
        margin-bottom: 10px;
        border-radius: 4px;
        font-weight: bold;
        font-family: 'GothamXNarrow';
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.1;
        letter-spacing: 0.2px;
        color: #ffffff;
        background-color: #3c5a99;
        width: 100%;
        cursor: pointer;
    }

    .info-block-info {
        bottom: 30px;
        left: 0px;
        right: 0px;
        background-color: transparent;
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
        justify-content: flex-end;
    }

    .info-block .info-block-image {
        width: 70%;
    }

    .mobile {
        width: 90% !important;
        display: block !important;
    }

    .desktop {
        display: none !important;
    }

    .ml-15 {
        margin-left: 0px;
    }

    .mr-15 {
        margin-right: 0px;
    }

    .item-container {
        flex-direction: column;
    }

    .item-container-reverted-mobile {
        flex-direction: column-reverse;
    }

    .item-container-image {
        margin-top: 44px;
    }

    .item-container-info {
        text-align: center;
        padding-left: 30px;
        padding-right: 30px;
        width: calc(100% - 60px);
    }

    .teaser {
        text-align: center;
        padding-left: 30px;
        padding-right: 30px;
        width: calc(100% - 60px);
    }

    h1 {
        text-align: center;
        padding-left: 30px;
        padding-right: 30px;
    }

    .item-container-image {
        width: 90%;
        height: auto;
    }
}

.footer-links {
    margin-top: 100px;
    margin-bottom: 50px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.footer-links .link {
    text-decoration: none;
    opacity: 0.7;
    font-family: 'GothamNarrow';
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #ffffff;
    padding: 0px 20px;
}

.cookieConsent {
    background-color: #00331c !important;
    font-size: 16px !important;
    line-height: 22px !important;
    left: 20px !important;
    right: 20px !important;
    bottom: 20px !important;
    width: calc(100vw - 40px) !important;
}


a {
    text-decoration: underline !important;
    cursor: pointer;
}


button {
    background-color: #00331c !important;
    color: #fff !important;
    text-align: center !important;
    width: calc(100% - 30px) !important;
    font-size: 16px !important;
    line-height: 22px !important;
}


.footer-links .link:hover {
    text-decoration: none;
    opacity: 1;
    font-family: 'GothamNarrow';
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #ffffff;
    padding: 0px 20px;
}

@media only screen and (max-width: 400px) {
    .info-block {
        align-items: center;
    }

    .mobile {
        width: 90% !important;
        align-self: center;
    }

    .center-image {
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .btn-facebooklogin {
        display: block;
        font-size: 16px;
        border-style: none;
        padding: 15px;
        margin-bottom: 10px;
        border-radius: 4px;
        font-weight: bold;
        font-family: 'GothamXNarrow';
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.1;
        letter-spacing: 0.2px;
        color: #ffffff;
        background-color: #3c5a99;
        width: 100%;
        cursor: pointer;
    }

    .mbt-30 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .main-login-page {}

    .info-block {
        background-color: #007234;
        height: auto !important;
        min-height: calc(100vh - 50px) !important;
    }
}

.desktop {
    display: block !important;
}